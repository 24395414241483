import { AppAccordion, AppSwitch } from '@t4b/core/lib'
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Alert, Button, Card, Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { convertPlatformFields, FixApiOZPlatformEntity, FixApiPlatformEntity, FixApiPXMPlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform, onlyFetchPlatformAccountsNames } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput, passwordInput, timespanInput, sselectInput } from '../../utils/controls'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { getJwt } from '../../redux/actions/authentication'
import NewSearchableSelectInput from '../inputs/NewSearchableSelectInput'
import DateTimeInput from '../inputs/DateTimeInput'
import moment from 'moment/moment'
import { checkTextResponse, downloadFile } from '../../utils/fetch-utils'
import { LoadSpinnerAction } from '../../redux/reducers/sysLpsReducer'
import { RootState } from '../../redux/reducers/rootReducer'
import * as yup from 'yup'
import { withTooltipPreventOverflow } from '../ConfTable'

interface IFixApiPlatformEdit extends IGatewayProp {
  data: any
  isValidParent?: () => boolean
}

const FixApiPlatformEdit: React.FC<IFixApiPlatformEdit> = ({ data: { item, type, port }, isValidParent, gateway, gatewayName }) => {
  const dispatch = useDispatch()
  const { LoadSpinner } = useSelector((state: RootState) => state.sysLps)

  const fixPlatform = item.Type === 'fixapi' ? new FixApiPlatformEntity(item) : item.Type === 'fixapipxm' ? new FixApiPXMPlatformEntity(item) : new FixApiOZPlatformEntity(item)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(fixPlatform, FixApiPlatformEntity.schema())
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(
    new ConnectionString(item.ConnectionString || {}),
    ConnectionString.schema(type),
  )

  const [inputStateStatements, setInputStateStatements, touchedStatements, setTouchedStatements, errorsStatements, isValidStatements] = useFormValidation(
    { ...inputState, StatementsTime: inputState.StatementsType === 'Disabled' ? 0 : inputState.StatementsTime },
    {
      StatementsSenderLogin: inputState.StatementsSenderEnabled ? yup.string().required() : yup.string(),
      StatementsSenderHost: inputState.StatementsSenderEnabled ? yup.string().required() : yup.string(),
      StatementsSenderPort: inputState.StatementsSenderEnabled
        ? yup
            .string()
            .matches(/^[0-9]+$/gi)
            .test('test', 'test', value => value > 0)
        : yup.string(),
      StatementsSenderPassword: inputState.StatementsSenderEnabled ? yup.string().required() : yup.string(),
    },
  )

  const [hidden, setHidden] = useState(false)

  const [accounts, setAccounts] = useState<any>([])
  const [generateReportState, setGenerateReportState] = useState({
    From: moment(new Date()).utc().startOf('day').subtract(1, 'day').toDate().getTime(),
    To: moment(new Date()).utc().endOf('day').toDate().getTime(),
    StatementsMode: { value: 'CSV', label: 'CSV' },
    StatementsAccount: { value: '', label: '' },
  })

  const [hiddenStatement, setHiddenStatement] = useState(true)

  const ref = useRef<any>(null)
  const refS = useRef<any>(null)
  const dbRef = useRef<any>(null)

  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
      FileStorePath: item.FileStorePath,
      FileLogPath: item.FileLogPath,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type !== 'add') {
      onlyFetchPlatformAccountsNames({ Platform: inputState.Name, Gateway: gateway.Name }).then((data: any) => setAccounts(buildMultiselectOptionsFromArray(data)))
    }
  }, [type])

  useEffect(() => {
    setGenerateReportState((prev: any) => {
      return {
        ...prev,
        StatementsAccount: accounts[0],
      }
    })
  }, [accounts.length])

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)

  const dbEditor = (
    <DbConnectionEditor
      type={type}
      state={connectionString}
      setState={setConnectionString}
      touched={connTouched}
      setTouched={setConnTouched}
      errors={connErrors}
      isDefaultSettings={isDefaultSettings}
      setIsDefaultSettings={setDefaultDb}
    />
  )

  const statements = buildControlsExtTwoPerLine(
    [
      sselectInput('StatementsType', buildMultiselectOptionsFromArray(['Disabled', 'Daily'])).optionZindex(false),
      timespanInput('StatementsTime').skipWhen(inputStateStatements.StatementsType.value === 'Disabled'),
      sselectInput('StatementsMode', buildMultiselectOptionsFromArray(['HTML', 'CSV']))
        .optionZindex(false)
        .skipWhen(inputStateStatements.StatementsType.value === 'Disabled'),
    ],
    inputStateStatements,
    setInputStateStatements,
    'statements',
    touchedStatements,
    setTouchedStatements,
    errorsStatements,
  )

  const newPort = port.filter((elem: any) => elem !== item.SocketAcceptPort)
  const alreadyExist = () => {
    if (port.map(String).includes(String(inputState.SocketAcceptPort)) && type === 'add') {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    if (newPort.map(String).includes(String(inputState.SocketAcceptPort))) {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    return ''
  }

  const editPlatform = buildControlsExtTwoPerLine(
    [
      textInput('SocketAcceptPort').errorMessage(alreadyExist()),
      checkboxInput('UseLocalTime'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      textInput('ExternalIp'),
      textInput('ExternalPort'),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const ssl = buildControlsExtTwoPerLine(
    [
      checkboxInput('SSLEnable'),
      textInput('SslCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!inputState.SSLEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      textInput('SslServerName').disabled(!inputState.SSLEnable).holder('optional'),
      sselectInput('SslProtocols', buildMultiselectOptionsFromArray(gateway?.SslProtocols) ?? [])
        .disabled(!inputState.SSLEnable)
        .textMessages(
          inputState.SslProtocols.value === 'SSL 2.0' || inputState.SslProtocols.value === 'SSL 3.0'
            ? 'Protocol is deprecated. Not all systems support it, the connection may not work!'
            : '',
        ),
      checkboxInput('SslCheckCertificateRevocation').disabled(!inputState.SSLEnable),
      checkboxInput('SslRequireClientCertificate').disabled(!inputState.SSLEnable),
      checkboxInput('SslValidateCertificates').disabled(!inputState.SSLEnable),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const loadGenerateReport = async () => {
    dispatch(LoadSpinnerAction(true))

    const url = new URL(`api/platform/getfixstatements`, window.location.origin)
    url.searchParams.set('platform', inputState.Name)
    url.searchParams.set('gateway', gateway.Name)
    url.searchParams.set('from', generateReportState.From.toString())
    url.searchParams.set('to', generateReportState.To.toString())
    url.searchParams.set('account', generateReportState.StatementsAccount.value)
    url.searchParams.set('type', generateReportState.StatementsMode.value.toLowerCase())

    try {
      await fetch(`${url}`, {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      })
        .then((response: Response) => checkTextResponse(response))
        .then((data: any) => {
          if (data.type === 'application/zip') {
            downloadFile(`${inputState.Name} - Account(${generateReportState.StatementsAccount.value}).zip`, data)
          } else {
            downloadFile(`${inputState.Name} - Account(${generateReportState.StatementsAccount.value}).${generateReportState.StatementsMode.value.toLowerCase()}`, data)
          }
        })

      dispatch(LoadSpinnerAction(false))
    } catch (e) {
      dispatch(LoadSpinnerAction(false))
    }
  }

  const generateReport = (
    <Accordion defaultActiveKey={hidden ? 'accordion' : undefined} style={{ width: '100%' }}>
      <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
        <Card.Header
          className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak"
          style={{ width: '100%' }}
          onClick={() => setHidden(prev => !prev)}
        >
          <Accordion.Toggle eventKey="accordion" className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`} as="div">
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
              <div style={{ width: '70%' }}>Generate report now</div>
              <FontAwesomeIcon icon={faAngleRight} className={hidden ? 'icon ml-auto' : 'ml-auto icon icon-rotate'} />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="accordion">
          <Card.Body className="accordion-custom__collapse accordion-collapse d-flex">
            <div className="flex-column" style={{ width: '100%' }}>
              <div className="d-flex align-items-center justify-content-between">
                <DateTimeInput
                  showDateNotTime
                  zIndex={'none'}
                  state={generateReportState}
                  setState={setGenerateReportState}
                  name="From"
                  label="statements.From"
                  className=" settings-block__field styleWidth"
                  maxDate={moment(generateReportState.To - 86400000)
                    .utc()
                    .toDate()}
                />
                <DateTimeInput
                  showDateNotTime
                  zIndex={'none'}
                  state={generateReportState}
                  setState={setGenerateReportState}
                  name="To"
                  label="statements.To"
                  className=" settings-block__field styleWidth"
                  minDate={moment(generateReportState.From).utc().toDate()}
                  maxDate={moment().utc().toDate()}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <NewSearchableSelectInput
                  zIndex={'none'}
                  placement="bottom"
                  state={generateReportState}
                  setState={setGenerateReportState}
                  name="StatementsAccount"
                  options={accounts}
                  label="statements.StatementsAccount"
                  className="styleWidth settings-block__field mb-3"
                />

                <NewSearchableSelectInput
                  zIndex={'none'}
                  state={generateReportState}
                  setState={setGenerateReportState}
                  name="StatementsMode"
                  options={buildMultiselectOptionsFromArray(['HTML', 'CSV'])}
                  label="statements.StatementsMode"
                  className="styleWidth settings-block__field mb-3"
                />
              </div>

              <Button className="t4b-bg-dark-button my-3" variant="primary" disabled={LoadSpinner} onClick={loadGenerateReport}>
                {LoadSpinner ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : null}
                {LoadSpinner ? 'Pending...' : 'Generate'}
              </Button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )

  if (type === 'modify' && inputStateStatements.flag) {
    errorsStatements.StatementsSenderPassword = true
  } else if (type === 'add' && !inputState.isDefaultSettings) {
    errorsStatements.StatementsSenderPassword = false
  } else {
    errorsStatements.StatementsSenderPassword = false
  }

  const statementsSenderEnabled = buildControlsExtTwoPerLine([checkboxInput('StatementsSenderEnabled')], inputState, setInputState, 'statements', touched, setTouched, errors)
  const statementsSender = buildControlsExtTwoPerLine(
    [
      textInput('StatementsSenderHost').disabled(!inputState.StatementsSenderEnabled),
      textInput('StatementsSenderPort').disabled(!inputState.StatementsSenderEnabled),
      textInput('StatementsSenderLogin').disabled(!inputState.StatementsSenderEnabled),
      passwordInput('StatementsSenderPassword').setMode(type).disabled(!inputState.StatementsSenderEnabled),
    ],
    inputStateStatements,
    setInputStateStatements,
    'statements',
    touchedStatements,
    setTouchedStatements,
    errorsStatements,
  )

  const statement = (
    <Accordion defaultActiveKey={hiddenStatement ? 'accordion' : undefined} style={{ width: '100%' }}>
      <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
        <Card.Header
          className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak"
          style={{ width: '100%' }}
          onClick={() => setHiddenStatement(prev => !prev)}
        >
          <Accordion.Toggle eventKey="accordion" className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`} as="div">
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
              <div style={{ width: '70%' }}>Regular report</div>
              <FontAwesomeIcon icon={faAngleRight} className={hiddenStatement ? 'icon ml-auto' : 'ml-auto icon icon-rotate'} />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="accordion">
          <Card.Body className="accordion-custom__collapse accordion-collapse d-flex">
            <div>
              <div className="flex-column" style={{ width: '100%' }}>
                {statements}
              </div>
              <div>{statementsSenderEnabled}</div>
              <div>{statementsSender}</div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )

  const options = [
    { value: 'Disabled', label: 'Disabled' },
    { value: 'ExecutionOnly', label: 'Only for LPs with executions' },
    { value: 'All', label: 'For all LPs in aggregation pool' },
  ]
  const editSnapshots = buildControlsExtTwoPerLine(
    [sselectInput('SaveLpBookSnapshots', options), checkboxInput('SavePlatformBookSnapshot')],
    inputState,
    setInputState,
    'Snapshots',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    const cond1 = isValid()
    let cond2 = isConnValid()
    let statementsValid = isValidStatements()

    if (!statementsValid) {
      return
    }

    if (isValidParent) {
      let validName = isValidParent()
      if (!validName) {
        return
      }
    }

    if (!cond1) {
      ref?.current?.open()
    }

    if (!cond2) {
      dbRef?.current?.open()
    }

    if (!cond1 || !cond2) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ExternalPort: inputState.ExternalPort === '' ? null : inputState.ExternalPort,
            SslProtocols: inputState.SslProtocols.value,
            ConnectionString: connectionString,
            StatementsTime: inputStateStatements.StatementsTime,
            StatementsType: inputStateStatements.StatementsType.value,
            StatementsMode: inputStateStatements.StatementsMode.value,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
            StatementsSenderHost: inputStateStatements.StatementsSenderHost,
            StatementsSenderLogin: inputStateStatements.StatementsSenderLogin,
            StatementsSenderPassword: inputStateStatements.StatementsSenderPassword,
            StatementsSenderPort: inputStateStatements.StatementsSenderPort,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            ExternalPort: inputState.ExternalPort === '' ? null : inputState.ExternalPort,
            SslProtocols: inputState.SslProtocols.value,
            ConnectionString: connectionString,
            StatementsTime: inputStateStatements.StatementsTime,
            StatementsType: inputStateStatements.StatementsType.value,
            StatementsMode: inputStateStatements.StatementsMode.value,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
            StatementsSenderHost: inputStateStatements.StatementsSenderHost,
            StatementsSenderLogin: inputStateStatements.StatementsSenderLogin,
            StatementsSenderPassword: inputStateStatements.StatementsSenderPassword,
            StatementsSenderPort: inputStateStatements.StatementsSenderPort,
          },
        }),
      )
    }
  }

  return (
    <>
      <AppAccordion
        item={{
          title: (
            <FormattedMessage
              id={item.Type === 'fixapi' ? 'platform.rightbar.edit.fix' : item.Type === 'fixapipxm' ? 'platform.rightbar.edit.fixapipxm' : 'platform.rightbar.edit.fixOz'}
              values={
                item.Type === 'fixapi'
                  ? { Type: type === 'add' ? 'FIX-API' : `Modify ${item.Name} (FIX-API)` }
                  : item.Type === 'fixapipxm'
                  ? { Type: type === 'add' ? 'FIX-TypeX' : `Modify ${item.Name} (FIX-TypeX)` }
                  : { Type: type === 'add' ? 'FIX-TypeO' : `Modify ${item.Name} (FIX-TypeO)` }
              }
            />
          ),
          item: (
            <div>
              {editPlatform}
              <Alert className="d-flex mt-2 mb-0" variant="warning">
                <FormattedMessage id="platform.rightbar.warning" />
              </Alert>
            </div>
          ),
        }}
        ref={ref}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.ssl" />,
          item: ssl,
        }}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.Statements" />,
          item: (
            <div>
              <div>{statement}</div>
              <div>{generateReport}</div>
            </div>
          ),
        }}
        ref={refS}
        render={refS.current}
      />
      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.Snapshots" />,
          item: editSnapshots,
        }}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}

export default withGateway(FixApiPlatformEdit)
