import { buildMultiselectOptionsFromArray } from '../utils/multiselect-utils'
import { Changable } from './configuration'
import { IMultiSelectItem } from './multiselect'
import * as yup from 'yup'
import { transformEmptyStringMaxLength, validNumber } from '../utils/schema-utils'

export class FeedLpRule {
  Id: number
  Symbols: IMultiSelectItem[]
  SenderCompId: string
  TargetCompId: string
  BeginString: string
  Username: string
  Password: string
  ReconnectInterval: number
  FileStorePath: string
  FileLogPath: string
  WriteLogs: boolean
  StartTime: Date
  EndTime: Date
  SocketConnectHost: string
  SocketConnectPort: number

  constructor(item: any) {
    this.Id = item.Id
    this.Symbols = item.Symbols ? buildMultiselectOptionsFromArray(item.Symbols) : []
    this.SenderCompId = item.SenderCompId
    this.TargetCompId = item.TargetCompId
    this.BeginString = item.BeginString
    this.Username = item.Username
    this.Password = item.Password
    this.ReconnectInterval = item.ReconnectInterval
    this.FileStorePath = item.FileStorePath ? item.FileStorePath : item.Name + '_store'
    this.FileLogPath = item.FileLogPath ? item.FileLogPath : item.Name + '_log'
    this.WriteLogs = item.WriteLogs
    this.StartTime = item.StartTime
    this.EndTime = item.EndTime
    this.SocketConnectHost = item.SocketConnectHost
    this.SocketConnectPort = item.SocketConnectPort
  }
}

export class Depth {
  Depth: number

  constructor(depth: number) {
    this.Depth = depth
  }

  public toString() {
    if (this.Depth) {
      return this.Depth.toString()
    }
    return 'undefined'
  }
}

export class LiquidityProvider extends Changable {
  Id: number
  Name: string
  Type: string
  Depth: number
  Enabled: boolean
  Profile: any
  isSelectedChecked: boolean
  flag: boolean
  bunchProfiles?: any
  flagImport?: boolean

  static schema = {
    Name: yup.string().required(),
    Type: yup.string().required(),
    Depth: yup
      .string()
      .matches(/^[0-9]+$/gi)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
  }

  constructor(item?: any) {
    super(item)
    if (item) {
      this.flagImport = item.flagImport
      this.isSelectedChecked = item.isSelectedChecked
      this.Id = item.Id
      this.Name = item.Name
      this.Type = item.Type
      this.Depth = item.Depth
      this.Enabled = item.Enabled
      this.Profile = item.Profile
      this.flag = item.flag ?? false
      this.bunchProfiles = item.bunchProfiles ?? [item.Profile]
    } else {
      this.flagImport = false
      this.bunchProfiles = []
      this.isSelectedChecked = false
      this.Profile = []
      this.Id = 0
      this.Name = ''
      this.Type = 'LMAX'
      this.Depth = 0
      this.Enabled = true
      this.flag = false
    }
  }
}

export enum FeedAggTypes {
  MultiBook = 'MultiBook',
  TopPrice = 'TopPrice',
}

export enum MarkupType {
  Fixed = 'Fixed',
  Range = 'Range',
  MiniMax = 'MiniMax',
  Proportional = 'Proportional',
}

export class FeedAgregationEntity extends Changable {
  Id: number
  Name: string
  Type: FeedAggTypes
  Feeders: number[]
  FeedersNames: any[]

  bunchProfiles: any
  isSelectedChecked: boolean
  flagImport?: boolean

  constructor(item?: any) {
    super(item)
    if (item) {
      this.FeedersNames = item.FeedersNames
      this.flagImport = item.flagImport
      this.bunchProfiles = item.bunchProfiles
      this.isSelectedChecked = item.isSelectedChecked
      this.Id = item.Id || 0
      this.Name = item.Name || ''
      this.Type = item.Type || FeedAggTypes.TopPrice
      this.Feeders = item.Feeders || []
    } else {
      this.FeedersNames = []
      this.flagImport = false
      this.bunchProfiles = []
      this.isSelectedChecked = false
      this.Id = 0
      this.Name = ''
      this.Type = FeedAggTypes.TopPrice
      this.Feeders = []
    }
  }

  public hasLp(lpId: number): boolean {
    return !!this.Feeders.find((feeder: number) => feeder === lpId)
  }
}

export class FeedAgregationExtEntity extends FeedAgregationEntity {
  FeedersNames: IMultiSelectItem[]

  constructor(item: any) {
    super(item)
    this.FeedersNames = item.FeedersNames || []
  }
}

export function adjustFeedAggregation(feedAggregation: FeedAgregationEntity, lp: LiquidityProvider[]) {
  const lpNames = feedAggregation.Feeders.map((lpIndex: number) => {
    for (const lpItem of lp) {
      if (lpIndex === lpItem.Id) {
        return { value: lpIndex, label: lpItem.Name }
      }
    }
    return { value: 'unknown', label: 'unknown' }
  })
  return { ...feedAggregation, FeedersNames: lpNames }
}

export class FeedPlatformEntity extends Changable {
  Id: number
  Name: string
  Connector: string
  Type: string
  AggregatedFeeder: number
  BackupFeedAggregationId: number
  Rules: FeedPlatformRuleEntity[]
  Enabled: boolean
  Profile: string
  isSelectedChecked: boolean
  flagImport?: boolean
  newPlatformFlag?: boolean

  constructor(item?: any) {
    super(item)
    if (item) {
      this.newPlatformFlag = item.newPlatformFlag
      this.flagImport = item.flagImport
      this.isSelectedChecked = item.isSelectedChecked
      this.Id = item.Id
      this.Name = item.Name || ''
      this.Connector = item.Connector
      this.Type = item.Type || ''
      this.AggregatedFeeder = item.AggregatedFeeder
      this.BackupFeedAggregationId = item.BackupFeedAggregationId
      this.Rules = item.Rules ? item.Rules.map((symbol: any) => new FeedPlatformRuleEntity(symbol)) : []
      this.Enabled = item.Enabled
      this.Profile = item.Profile
    } else {
      this.newPlatformFlag = false
      this.flagImport = false
      this.isSelectedChecked = false
      this.Profile = ''
      this.Id = 0
      this.Name = ''
      this.Connector = ''
      this.Type = ''
      this.AggregatedFeeder = -1
      this.BackupFeedAggregationId = 0
      this.Rules = []
      this.Enabled = true
    }
  }
}

export class FeedPlatformRuleEntity {
  Id: number
  Symbols: IMultiSelectItem[]
  MaxLevels: number
  MarkupType: MarkupType
  MarkupAsk: number
  MarkupBid: number
  MinSpread: number
  MaxSpread: number
  Multiplier: number
  Rounding: string
  SpreadBalance: any;
  [key: string]: any
  HardFilterPercent: any
  HardFilterCount: any
  SoftFilterPercent: any
  SoftFilterCount: any
  LimitationPercent: any
  FilterInvalidQuotes: boolean

  static schema = {
    Symbols: yup.array().required(),
    MaxLevels: yup.number().integer().min(0).max(1000000000),
    MarkupType: yup.string().required(),
    MarkupBid: yup.number().integer().min(-1000000000).max(1000000000),
    MarkupAsk: yup.number().integer().min(-1000000000).max(1000000000),
    MinSpread: yup.number().integer().min(0).max(1000000000),
    MaxSpread: yup.number().integer().min(0).max(1000000000),
    HardFilterPercent: yup.number().integer().min(0).max(1000000000),
    HardFilterCount: yup.number().integer().min(0).max(1000000000),
    SoftFilterPercent: yup.number().integer().min(0).max(1000000000),
    SoftFilterCount: yup.number().integer().min(0).max(1000000000),
    LimitationPercent: yup.number().integer().min(0).max(1000000000),

    Multiplier: yup
      .string()
      .matches(/^(?=.*\d)\d*(?:\.\d{0,2})?$/gi)
      .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value <= 100000),
  }

  constructor(item?: any) {
    if (item) {
      this.FilterInvalidQuotes = item.FilterInvalidQuotes
      this.HardFilterPercent = item.HardFilterPercent
      this.HardFilterCount = item.HardFilterCount
      this.SoftFilterPercent = item.SoftFilterPercent
      this.SoftFilterCount = item.SoftFilterCount
      this.LimitationPercent = item.LimitationPercent
      this.SpreadBalance = item.SpreadBalance
      this.Rounding = item.Rounding
      this.Multiplier = item.Multiplier
      this.Id = item.Id
      this.Symbols = item.Symbols ? buildMultiselectOptionsFromArray(item.Symbols) : []
      this.MaxLevels = item.MaxLevels
      this.MarkupType = item.MarkupType
      this.MarkupAsk = item.MarkupAsk
      this.MarkupBid = item.MarkupBid
      this.MinSpread = item.MinSpread
      this.MaxSpread = item.MaxSpread
    } else {
      this.FilterInvalidQuotes = false
      this.HardFilterPercent = '30'
      this.HardFilterCount = '10'
      this.SoftFilterPercent = '10'
      this.SoftFilterCount = '5'
      this.LimitationPercent = '50'
      this.SpreadBalance = 1
      this.Rounding = 'Floor'
      this.Multiplier = 1
      this.Id = 0
      this.Symbols = buildMultiselectOptionsFromArray(['*'])
      this.MaxLevels = 10
      this.MarkupType = MarkupType.Fixed
      this.MarkupAsk = 0
      this.MarkupBid = 0
      this.MinSpread = 0
      this.MaxSpread = 0
    }
  }
}

export class FeedPlatformRuleEntityMt4 {
  Id: number
  Symbols: IMultiSelectItem[]
  MaxLevels: number
  MarkupType: MarkupType
  MarkupAsk: number
  MarkupBid: number
  MinSpread: number
  MaxSpread: number;
  [key: string]: any

  static schema = {
    Symbols: yup.array().required(),
    MaxLevels: yup.number().integer().required(),
    MarkupType: yup.string().required(),
    MarkupBid: yup.number().integer().required(),
    MarkupAsk: yup.number().integer().required(),
    MinSpread: yup.number().integer().min(0).required(),
    MaxSpread: yup.number().integer().min(0).required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Symbols = item.Symbols ? buildMultiselectOptionsFromArray(item.Symbols) : []
      this.MaxLevels = item.MaxLevels
      this.MarkupType = item.MarkupType
      this.MarkupAsk = item.MarkupAsk
      this.MarkupBid = item.MarkupBid
      this.MinSpread = item.MinSpread
      this.MaxSpread = item.MaxSpread
    } else {
      this.Id = 0
      this.Symbols = buildMultiselectOptionsFromArray(['*'])
      this.MaxLevels = 1
      this.MarkupType = MarkupType.Range
      this.MarkupAsk = 0
      this.MarkupBid = 0
      this.MinSpread = 0
      this.MaxSpread = 0
    }
  }
}
