import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { fetchAddReport, fetchRegenerateReport, fetchReportRules } from '../../../redux/actions/reports-actions'
import { RootState } from '../../../redux/reducers/rootReducer'
import { AppCheckboxCustom } from '../../app-checkbox-custom'
import { buildControlsExtTwoPerLine, checkboxInput, mselectInput, sselectInput, textInput, timeInput, timespanInput } from '../../../utils/controls'
import { buildMultiselectOptionsFromArray, buildSelectOption, optionsToStrings } from '../../../utils/multiselect-utils'
import { AppAccordion } from '@t4b/core'
import { GeneratedFiles } from './GeneratedFiles'
import { useTranslate } from '../../../hooks/useTranslate'
import moment from 'moment/moment'
import { localAsUTC } from '../../../utils/time-utils'
import * as yup from 'yup'

export const ReportRightbar = ({ data: { item, type, params, platformName } }: any) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { Reports } = useSelector((state: RootState) => state.Reports)
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const mainRef = useRef<any>(null)
  const activePlatform = gateway?.Platforms?.find((item: any) => platformName === item.Name)?.Type
  const [checked, setChecked] = useState({
    EXRA: item.Type === 'Exra',
    EMIR: item.Type === 'Emir',
  })

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      UserGroupMask: item.UserGroupMask === '' ? '*' : item.UserGroupMask,
      ExcludedAccounts: Array.isArray(item.ExcludedAccounts) ? item.ExcludedAccounts.join(',') : '',
      Pools: Array.isArray(item.Pools) ? item.Pools.join(',') : '',
      DateOfMonth: buildSelectOption(item.DateOfMonth > 0 ? String(item.DateOfMonth) : '1'),
      DaysOfWeek: buildMultiselectOptionsFromArray(item.DaysOfWeek.length ? item.DaysOfWeek : ['Monday']),
      From: moment(localAsUTC(new Date())).utc().startOf('day').toDate().getTime(),
      ScheduleType: { label: translate(`report.${item.ScheduleType}`), value: item.ScheduleType },
    },
    {
      ScheduleRuleName: type === 'regenerate' ? yup.string() : yup.string().required(),
      StartTime: yup.number().required(),
      ScheduleType: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
      ExcludedAccounts: yup
        .string()
        .matches(/^[0-9,]+$/gi)
        .nullable(),

      DateOfMonth: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),

      DaysOfWeek: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          }),
        )
        .required(),
    },
  )
  const [validExcel, setValidExcel] = useState(item?.Excel?.Enabled)
  const [inputStateColumns, setInputStateColumns, touchedColumns, setTouchedColumns, errorsColumns, isValidColumns] = useFormValidation(
    {
      ...item?.Excel,
      ExecutionColumns: type === 'add' ? buildMultiselectOptionsFromArray(['ALL']) : buildMultiselectOptionsFromArray(item.Excel?.ExecutionColumns),
      RejectedColumns: type === 'add' ? buildMultiselectOptionsFromArray(['ALL']) : buildMultiselectOptionsFromArray(item.Excel?.RejectedColumns),
    },
    checked.EXRA
      ? {
          Name: validExcel ? yup.string().required() : yup.string(),
          Folder: validExcel ? yup.string().required() : yup.string(),
          ExecutionColumns: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                label: yup.string().required(),
              }),
            )
            .required(),
          RejectedColumns: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                label: yup.string().required(),
              }),
            )
            .required(),
        }
      : {},
  )
  const [validUsers, setValidUsers] = useState(item?.Users?.Enabled)
  const [inputStateUsers, setInputStateUsers, touchedUsers, setTouchedUsers, errorsUsers, isValidUsers] = useFormValidation(item?.Users ?? {}, {
    Name: type === 'regenerate' || checked.EXRA ? yup.string() : validUsers ? yup.string().required() : yup.string(),
  })
  const [validQuotes, setValidQuotes] = useState(item?.Quotes?.Enabled)
  const [inputStateQuotes, setInputStateQuotes, touchedQuotes, setTouchedQuotes, errorsQuotes, isValidQuotes] = useFormValidation(
    {
      ...(item?.Quotes ?? {}),
      ExpirationTimeout: item.ExpirationTimeout ?? 900000,
    },
    {
      Name: type === 'regenerate' || activePlatform !== 'mt5' ? yup.string() : validQuotes && activePlatform === 'mt5' ? yup.string().required() : yup.string(),
    },
  )

  const [validDeals, setValidDeals] = useState(item?.Deals?.Enabled)
  const [inputStateDeals, setInputStateDeals, touchedDeals, setTouchedDeals, errorsDeals, isValidDeals] = useFormValidation(item?.Deals ?? {}, {
    Name: checked.EXRA ? yup.string() : validDeals ? yup.string().required() : yup.string(),
  })

  useEffect(() => {
    if (inputState.ExcludedAccounts === '') {
      errors.ExcludedAccounts = false
    }
  }, [inputState.ExcludedAccounts])

  useEffect(() => {
    setValidExcel(inputStateColumns.Enabled)
    setValidDeals(inputStateDeals.Enabled)
    setValidUsers(inputStateUsers.Enabled)
    setValidQuotes(inputStateQuotes.Enabled)
  }, [inputStateColumns.Enabled, inputStateDeals.Enabled, inputStateUsers.Enabled, inputStateQuotes.Enabled])

  const onChange = (item: any) => {
    if (item.EXRA) {
      setChecked({ EXRA: true, EMIR: false })
    }

    if (item.EMIR) {
      setChecked({ EXRA: false, EMIR: true })
    }
  }

  const handleClick = async () => {
    const body = {
      ...inputState,
      ExcludedAccounts: inputState.ExcludedAccounts.length > 0 ? inputState.ExcludedAccounts.split(',').map((item: any) => item?.trim()) : [],
      Pools: inputState.Pools.length > 0 ? inputState.Pools.split(',').map((item: any) => item?.trim()) : [],
      DateOfMonth: inputState.DateOfMonth.value,
      DaysOfWeek: optionsToStrings(inputState.DaysOfWeek),
      ScheduleType: inputState.ScheduleType.value,
      Type: checked.EMIR ? 'Emir' : 'Exra',
      Excel: {
        ...inputStateColumns,
        ExecutionColumns: optionsToStrings(inputStateColumns.ExecutionColumns),
        RejectedColumns: optionsToStrings(inputStateColumns.RejectedColumns),
      },
      Users: inputStateUsers,
      Quotes: inputStateQuotes,
      Deals: inputStateDeals,
    }

    const a = isValid()
    const b = isValidUsers()
    const c = isValidQuotes()
    const d = isValidDeals()
    const e = isValidColumns()

    if (!a || !b || !c || !d || !e) {
      return
    }

    if (type === 'add' || type === 'clone') {
      await dispatch(
        fetchAddReport({
          Gateway: gateway.Name,
          Platform: platformName,
          ruleName: inputState.ScheduleRuleName,
          setLoading: params.setLoading,
          body: body,
        }),
      )
    }

    if (type === 'modify') {
      await dispatch(
        fetchAddReport({
          Gateway: gateway.Name,
          Platform: platformName,
          ruleName: item.ScheduleRuleName,
          setLoading: params.setLoading,
          body: body,
        }),
      )
    }

    if (type === 'regenerate') {
      dispatch(
        fetchRegenerateReport({
          Gateway: gateway.Name,
          Platform: platformName,
          setLoading: params.setLoading,
          from: inputState.From,
          body: body,
          fileName: inputStateDeals.Name.length ? inputStateDeals.Name : inputStateColumns.Name,
        }),
      )
    }

    dispatch(
      fetchReportRules({
        Gateway: gateway.Name,
        Platform: platformName,
        setLoading: params.setLoading,
      }),
    )

    dispatch(hideRightBar())
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && Reports.find((item: any) => item.ScheduleRuleName === inputState.ScheduleRuleName)) {
      errors.ScheduleRuleName = true
      return 'reportRightbar.exists'
    }

    if (type === 'modify' && Reports.find((item: any) => item.ScheduleRuleName === inputState.ScheduleRuleName && item.Id !== inputState.Id)) {
      errors.ScheduleRuleName = true
      return 'reportRightbar.exists'
    }
    return ''
  }

  const reportSettings = buildControlsExtTwoPerLine(
    [
      textInput('ScheduleRuleName')
        .skipWhen(type === 'regenerate')
        .errorMessage(alreadyExist()),
      textInput('InvestmentFirmBBook'),
      timespanInput('StartTime', 200),
      textInput('ExcludedAccounts'),
      textInput('Pools'),
      textInput('ExecutionWithinFirmIdType').skipWhen(checked.EXRA),
      textInput('UserGroupMask', false, '', '', 'Mask', true).skipWhen(checked.EXRA),
    ],
    inputState,
    setInputState,
    'ReportRightbar',
    touched,
    setTouched,
    errors,
  )

  const ScheduleTypeOptions = [
    { label: translate('report.Daily'), value: 'Daily' },
    // { label: translate('report.Weekly'), value: 'Weekly' },
    // { label: translate('report.Monthly'), value: 'Monthly' },
  ]

  const DaysOfWeekOptions = buildMultiselectOptionsFromArray(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
  const DateOfMonthOptions = Array.from({ length: 31 }, (_, index) => index + 1)?.map((item: any) => {
    return {
      value: String(item),
      label: String(item),
    }
  })

  const reportSchedule = buildControlsExtTwoPerLine(
    [
      sselectInput('ScheduleType', ScheduleTypeOptions)
        .skipWhen(type === 'regenerate')
        .optionZindex(false),
      timeInput('From', 100).skipWhen(type !== 'regenerate'),
      mselectInput('DaysOfWeek', DaysOfWeekOptions, 350).skipWhen(
        inputState.ScheduleType.value === 'Daily' || inputState.ScheduleType.value === 'Monthly' || type === 'regenerate',
      ),
      sselectInput('DateOfMonth', DateOfMonthOptions).skipWhen(inputState.ScheduleType.value === 'Daily' || inputState.ScheduleType.value === 'Weekly' || type === 'regenerate'),
    ],
    inputState,
    setInputState,
    'ReportRightbar',
    touched,
    setTouched,
    errors,
  )

  const RejectedColumnsOptions = buildMultiselectOptionsFromArray([
    'ALL',
    'ORDER_ID',
    'ORDER_TYPE',
    'OPEN_TIME_UTC',
    'TRADING_PLATFORM',
    'LOGIN',
    'SYMBOL',
    'CMD',
    'VOLUME',
    'PLATFORM_REQUEST_PRICE',
    'ORDER_STATUS',
  ])
  const ExecutionColumnsOptions = buildMultiselectOptionsFromArray([
    'ALL',
    'TICKET',
    'SECONDARY_TICKET',
    'PLATFORM_TICKET',
    'LOGIN',
    'SYMBOL',
    'CMD',
    'VOLUME',
    'OPEN_TIME_UTC',
    'OPEN_TIME_PLATFORM',
    'PLATFORM_REQUEST_PRICE',
    'LP_REQUEST_PRICE',
    'LP_EXECUTION_PRICE',
    'PLATFORM_EXECUTION_PRICE',
    'PLATFORM_SLIPPAGE',
    'LP_SLIPPAGE',
    'BROKER_MARKUP',
    'LP',
    'TRADING_PLATFORM',
    'LP_EXECUTION_TIME_MS',
    'FULL_EXECUTION_TIME_MS',
    'ORDER_ID',
    'ORDER_TYPE',
    'ORDER_STATUS',
  ])

  const findAllExecutionColumns = inputStateColumns?.ExecutionColumns?.find((item: any) => item?.value === 'ALL')
  const findAllRejectedColumns = inputStateColumns?.RejectedColumns?.find((item: any) => item?.value === 'ALL')
  const optionsExecutionColumns = findAllExecutionColumns?.value === 'ALL' ? [] : ExecutionColumnsOptions
  const optionsRejectedColumns = findAllRejectedColumns?.value === 'ALL' ? [] : RejectedColumnsOptions

  useEffect(() => {
    if (findAllExecutionColumns?.value === 'ALL') {
      setInputStateColumns((prev: any) => {
        return {
          ...prev,
          ExecutionColumns: [{ value: 'ALL', label: 'ALL' }],
        }
      })
    }

    if (findAllRejectedColumns?.value === 'ALL') {
      setInputStateColumns((prev: any) => {
        return {
          ...prev,
          RejectedColumns: [{ value: 'ALL', label: 'ALL' }],
        }
      })
    }
  }, [optionsExecutionColumns.length, optionsRejectedColumns.length])

  const reportColumns = buildControlsExtTwoPerLine(
    [mselectInput('ExecutionColumns', optionsExecutionColumns, 350).skipWhen(checked.EMIR), mselectInput('RejectedColumns', optionsRejectedColumns, 350).skipWhen(checked.EMIR)],
    inputStateColumns,
    setInputStateColumns,
    'ReportRightbar',
    touchedColumns,
    setTouchedColumns,
    errorsColumns,
  )

  const reportColumnsExel = buildControlsExtTwoPerLine(
    [checkboxInput('Enabled').skipWhen(type === 'regenerate'), textInput('Name').skipWhen(!inputStateColumns.Enabled), textInput('Folder').skipWhen(!inputStateColumns.Enabled)],
    inputStateColumns,
    setInputStateColumns,
    'reportColumnsExel',
    touchedColumns,
    setTouchedColumns,
    errorsColumns,
  )

  const fileDeals = buildControlsExtTwoPerLine(
    [checkboxInput('Enabled').skipWhen(type === 'regenerate'), textInput('Name').skipWhen(!inputStateDeals.Enabled), textInput('Folder').skipWhen(!inputStateDeals.Enabled)],
    inputStateDeals,
    setInputStateDeals,
    'ReportRightbarDeals',
    touchedDeals,
    setTouchedDeals,
    errorsDeals,
  )

  const fileQuotes = buildControlsExtTwoPerLine(
    [
      checkboxInput('Enabled'),
      textInput('Name').skipWhen(!inputStateQuotes.Enabled),
      textInput('Folder').skipWhen(!inputStateQuotes.Enabled),
      timespanInput('ExpirationTimeout').skipWhen(!inputStateQuotes.Enabled),
    ],
    inputStateQuotes,
    setInputStateQuotes,
    'ReportRightbarQuotes',
    touchedQuotes,
    setTouchedQuotes,
    errorsQuotes,
  )

  const fileUsers = buildControlsExtTwoPerLine(
    [checkboxInput('Enabled'), textInput('Name').skipWhen(!inputStateUsers.Enabled), textInput('Folder').skipWhen(!inputStateUsers.Enabled)],
    inputStateUsers,
    setInputStateUsers,
    'ReportRightbarUsers',
    touchedUsers,
    setTouchedUsers,
    errorsUsers,
  )

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`reportRightbar.${type}`} />
      </Card.Header>
      <Card.Body>
        <>
          <span className="reportTitle">
            <FormattedMessage id="ReportType" />
          </span>
          <div className="d-flex mt-3">
            <span className="mr-4 mb-4">
              <AppCheckboxCustom size={'18px'} text={'EXRA'} borderColor={'#2A5B88'} color={'#2A5B88'} sizeChecked={'10px'} checked={checked.EXRA} onChange={onChange} />
            </span>
            <span className="ml-4 mb-4">
              <AppCheckboxCustom size={'18px'} text={'EMIR'} borderColor={'#2A5B88'} color={'#2A5B88'} sizeChecked={'10px'} checked={checked.EMIR} onChange={onChange} />
            </span>
          </div>
        </>

        <div className="mt-4 mb-4">
          <span className="reportTitle">
            <FormattedMessage id="PlatformReportSettings" />
          </span>
          <div className="mt-2">{reportSettings}</div>
        </div>

        <div className="mt-4">
          <span className="reportTitle">
            <FormattedMessage id="ReportSchedule" />
          </span>
          <div className="mt-2">{reportSchedule}</div>
        </div>

        <div className="mt-4">
          <span className="reportTitle">
            <FormattedMessage id="ReportView" />
          </span>
          {checked.EXRA ? <div className="mt-2">{reportColumns}</div> : null}
          {checked.EMIR ? (
            <>
              <div className="mt-2">{fileDeals}</div>
              {activePlatform === 'mt5' && type !== 'regenerate' && <div>{fileQuotes}</div>}
              {type !== 'regenerate' && <div>{fileUsers}</div>}
            </>
          ) : (
            <div className="mt-2">{reportColumnsExel}</div>
          )}
        </div>
        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id={type === 'regenerate' ? 'regenerate' : 'save'} />
        </Button>
      </Card.Body>
      {type === 'modify' && (
        <AppAccordion
          item={{
            title: <FormattedMessage id={'GeneratedFiles'} />,
            item: item?.ReportFiles?.length ? (
              <GeneratedFiles item={item} gateway={gateway} platformName={platformName} />
            ) : (
              <div className="d-flex align-items-center justify-content-center">No reports were generated</div>
            ),
          }}
          ref={mainRef}
          render={mainRef?.current}
          isHidden={false}
        />
      )}
    </Card>
  )
}
