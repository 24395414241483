import { LMAXCryptoConnectorsClass, LpEntity } from '../../../entity/system-settings'

export const alreadyExist = (type: string, lps: any[], inputState: any, errors: any) => {
  if (type === 'add' && lps.find((lp: LpEntity) => lp.Name.toLowerCase() === inputState.Name.toLowerCase())) {
    errors.Name = true
    return 'lp.exists'
  }
  return ''
}

export const alreadyExistPreset = (type: string, lps: any[], inputState: any, errors: any) => {
  if (type === 'add' && lps.find((lp: LpEntity) => lp.Name === inputState.Name)) {
    errors.Name = true
    return 'preset.exists'
  }
  if (type === 'clone' && lps.find((lp: LpEntity) => lp.Name === inputState.Name)) {
    errors.Name = true
    return 'preset.exists'
  }

  return ''
}

export const alreadyExistPresetBar = (type: string, lps: any[], inputState: any, errors: any) => {
  if (type === 'add' && lps.find((lp: LpEntity) => lp.Name === inputState.Name)) {
    errors.Name = true
    return 'preset.exists'
  }
  if (type === 'clone' && lps.find((lp: LpEntity) => lp.Name === inputState.Name)) {
    errors.Name = true
    return 'preset.exists'
  }

  if (type === 'modify' && lps.find((lp: any) => lp.Name === inputState.Name && lp.Id !== inputState.Id)) {
    errors.Name = true
    return 'preset.exists'
  }

  return ''
}

export const alreadyExistFixExtSymbols = (type: string, fixSymbols: any[], inputState: any, errors: any) => {
  if (type === 'add' && fixSymbols.find((item: any) => item.PlatformSymbol === inputState.PlatformSymbol)) {
    errors.PlatformSymbol = true
    return 'fix.exists'
  }
  return ''
}

export const alreadyTest = (inputState: any, errors: any) => {
  if (inputState.Type.value === 'CFD') {
    errors.BaseCurrency = false
  }
  return ''
}

export const alreadyExistAccounts = (type: string, login: any[], inputState: any, errors: any) => {
  if (type === 'add' && login.find((item: LpEntity) => String(item) === inputState.Login)) {
    errors.Login = true
    return 'Login with such name already exists'
  }
  return ''
}

export const alreadyExistNames = (type: string, login: any[], inputState: any, errors: any) => {
  if (type === 'add' && login.find((item: LpEntity) => String(item) === inputState.Name)) {
    errors.Name = true
    return 'Name with such name already exists'
  }

  if (type === 'modify' && login.find((item: LpEntity) => String(item) === inputState.Name)) {
    errors.Name = true
    return 'Name with such name already exists'
  }

  if (type === 'clone' && login.find((item: LpEntity) => String(item) === inputState.Name)) {
    errors.Name = true
    return 'Name with such name already exists'
  }

  return ''
}

export const alreadyExistNameNotification = (type: string, login: any[], inputState: any, errors: any) => {
  if (type === 'add' && login.find((item: any) => item.Name === inputState.Name)) {
    errors.Name = true
    return 'Rule with such name already exists'
  }

  if (type === 'modify' && login.find((item: any) => item.Name === inputState.Name && inputState.Id !== item.Id)) {
    errors.Name = true
    return 'Rule with such name already exists'
  }

  if (type === 'clone' && login.find((item: any) => item.Name === inputState.Name && inputState.Id !== item.Id)) {
    errors.Name = true
    return 'Rule with such name already exists'
  }

  return ''
}

export const alreadyExistNameNotificationReceiver = (type: string, login: any[], inputState: any, errors: any) => {
  if (type === 'add' && login.find((item: any) => item.Name === inputState.Name)) {
    errors.Name = true
    return 'Receiver with such name already exists'
  }

  if (type === 'modify' && login.find((item: any) => item.Name === inputState.Name && inputState.Id !== item.Id)) {
    errors.Name = true
    return 'Receiver with such name already exists'
  }

  if (type === 'clone' && login.find((item: any) => item.Name === inputState.Name && inputState.Id !== item.Id)) {
    errors.Name = true
    return 'Receiver with such name already exists'
  }

  return ''
}

export const convert = (option: string) => {
  switch (option) {
    case 'LMAXCryptoF':
      return 'LMAX-CryptoFutures'
    case `${option}`:
      return `${option}`
  }
}

export const newObjectLMAXCrypto = (item: any) => {
  return {
    ...item.CryptoFutures,
    ConnectorParameters: {
      Connectors: item?.CryptoFutures?.ConnectorParameters?.Connectors?.map((item: any) => new LMAXCryptoConnectorsClass(item)),
    },
  }
}
