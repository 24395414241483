import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import AppButton from '../components/AppButton'
import { buildStringFromArray } from '../components/configuration/execution-configuration/value-builders'
import { optionsToStrings } from './multiselect-utils'
import { ISortOptions, ITableCallback, ITableColumn } from './table-types'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/reducers/rootReducer'

export function buildTableHeadersFromArray(arr: ITableColumn[], intl: string): JSX.Element[] {
  return arr.map(item => (
    <th key={item.name} className="position-relative">
      <FormattedMessage id={`${intl}.${item.name}`} />
    </th>
  ))
}

export function buildTableHeadersConfigured(arr: ITableColumn[], sortOptions: ISortOptions, setSortOptions: any, intl: string) {
  const getIcon = (fieldName: string) => {
    if (fieldName !== sortOptions.field) {
      return faSort
    }
    switch (sortOptions.by) {
      case 'none':
      default:
        return faSort
      case 'asc':
        return faSortUp
      case 'desc':
        return faSortDown
    }
  }

  const handleSort = (item: ITableColumn) => {
    let current = 'none'
    if (item.name === sortOptions.field) {
      current = sortOptions.by
    }
    if (current === 'none') {
      current = 'asc'
    } else if (current === 'asc') {
      current = 'desc'
    } else if (current === 'desc') {
      current = 'none'
    }
    setSortOptions({ field: item.name, by: current })
  }

  return arr
    .filter(item => item.show)
    .map(item => {
      const wrapper = () => handleSort(item)

      return (
        <th
          key={item.name}
          className={classNames('position-relative', item.className)}
          style={{
            width: item.className ? item.className : '',
          }}
        >
          <span
            style={{
              marginRight: 0,
              cursor: item.sortable === undefined || item.sortable ? 'pointer' : 'auto',
            }}
            onClick={item.sortable === undefined || item.sortable ? wrapper : undefined}
          >
            <FormattedMessage id={`${intl}.${item.name}`} />
            {item.sortable === undefined || item.sortable ? <FontAwesomeIcon icon={getIcon(item.name)} className="ml-1" style={{ height: '10px' }} /> : null}
          </span>
        </th>
      )
    })
}

export function buildTableData(
  data: any[],
  theader: ITableColumn[],
  onClick: ITableCallback | null,
  render?: ITableCallback,
  flag?: any,
  columStyle?: string,
  findId?: any,
): JSX.Element[] {
  return data.map((item: any, key: number) => {
    let handleClick: any = undefined
    if (typeof onClick === 'function') {
      handleClick = () => onClick(item)
    }

    const tds = theader
      .map((col, index: number) => {
        if (col.show && !col.skip) {
          let value = item[col.name]
          if (col.format) {
            value = col.format(value)
          }

          return (
            <td key={index} className={classNames(findId?.id === item?.id && flag && 'activeTest')}>
              <span className={columStyle === 'BUY' && col.name === 'DealPrice' ? 'buyTable' : columStyle === 'SELL' && col.name === 'DealPrice' ? 'sellTable' : ''}>{value}</span>
            </td>
          )
        }

        return null
      })
      .filter((td: any) => td !== null)
    return (
      <tr className={classNames(onClick && 'cursor-pointer')} key={key} onClick={handleClick}>
        {tds}
        {render && (
          <td className="d-flex justify-content-center" onClick={e => e.stopPropagation()}>
            {render(item)}
          </td>
        )}
      </tr>
    )
  })
}

export const defaultTableOptions = {
  settings: true,
  create: true,
  clone: true,
  update: true,
  delete: true,
}

export const withoutSettings = {
  settings: false,
  create: true,
  clone: true,
  update: true,
  delete: true,
}

export function buildTableDataExt(
  data: any[],
  theader: any,
  onClick: ITableCallback,
  onClone: ITableCallback,
  onDelete: ITableCallback,
  onExportOne?: ITableCallback,
  format?: any,
  options?: any,
): JSX.Element[] {
  if (!options) {
    options = defaultTableOptions
  }
  return data.map((item: any, key: number) => {
    const handleClick = options.update ? () => onClick(item) : undefined
    const handleClone = () => onClone(item)
    const handleDelete = () => onDelete(item)

    let handleExportOne = null
    if (onExportOne) {
      handleExportOne = () => onExportOne(item)
    }

    const tds = theader
      .map((col: any, index: number) => {
        if (col.show && col.actions !== true && col.name !== 'Actions') {
          let value = item[col.name]
          if (col.format) {
            value = col.format(value)
          }
          if (format && col.name === 'NewProcessor') {
            value = format(value)
          }
          return (
            <td key={index} className={item?.error === 1 ? 'error' : ''}>
              <span>{value}</span>
            </td>
          )
        }
        return null
      })
      .filter((td: any) => td !== null)

    return (
      <tr key={key} className={options.update ? 'cursor-pointer' : ''} onClick={handleClick}>
        {tds}
        {options.clone || options.delete ? (
          <td
            className={item?.error === 1 ? 'error' : ''}
            onClick={(event: any) => {
              event.stopPropagation()
            }}
          >
            <div className="d-flex justify-content-center">
              {options.clone ? <AppButton variant="clone" onClick={handleClone} className={(options.delete && 'mr-2') || ''} /> : null}
              {options.exportOne ? <AppButton variant="export-all" onClick={handleExportOne} className={(options.delete && 'mr-2') || ''} /> : null}
              {options.delete ? <AppButton variant="delete" onClick={handleDelete} /> : null}
            </div>
          </td>
        ) : null}
      </tr>
    )
  })
}

export const asTimespan = (value: any) =>
  moment(value)
    .locale(localStorage.getItem('lang') || 'en-gb')
    .utc()
    .format('HH:mm:ss')
export const asTime = (value: any, format = 'MMMM Do YYYY, HH:mm:ss') => {
  return value === 0
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        .utc()
        .format(format)
}

export const asTimeT = (value: any, format = 'MMMM Do YYYY, HH:mm:ss') => {
  return value === 0
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        // .utc()
        .format(format)
}

export const newAsTime = (value: any, format = 'YYYY/MM/DD HH:mm:ss') => {
  return value === 0 || typeof value === 'object'
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        .utc()
        .format(format)
}

export const asTimeF = (value: any, format = 'HH:mm:ss') => {
  return value === 0 || typeof value === 'object'
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        .utc()
        .format(format)
}

export const ArrayFromString = (value: string[]) => {
  return value?.join(', ')
}

export const asString = (value: any) => value.toString()
export const multiAsString = (value: any) => buildStringFromArray(optionsToStrings(value)).toString()
export const asCheckbox = (value: any) => {
  return value === 'N/A' ? value : <input type="checkbox" checked={value} readOnly />
}
export const numAsString = (value: number) => value.toFixed(2)
export const asMs = (ms: number) => `${ms} ms`
