import React, { useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import useVersion from '../../hooks/useVersion'
import { hideModal } from '../../redux/actions/modal-actions'
import { RootState } from '../../redux/reducers/rootReducer'

const apiVersion = '46'
const uiVersion = '3.5.2.701'

const ModalLicense: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const version = useVersion({ gateway: gateway.Name })

  const handleClose = useCallback(() => dispatch(hideModal()), [dispatch])

  if (version === null) {
    return null
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        <FormattedMessage id="modal.license" />
      </Modal.Header>
      <Modal.Body>
        <span className=" d-flex justify-content-center align-items-center mb-2">© 2023 - Tools For Brokers</span>
        <div className=" d-flex justify-content-between ">
          <div>
            <span>Gateway version {version.GatewayVersion}</span>
            <br />
            <span>Web version {version.WebVersion || 'N/A'}</span>
            <br />
            <span>UI version {uiVersion}</span>
          </div>
          <div>
            <span>Web API version {version.ApiVersion}</span>
            <br />
            <span>UI API version {apiVersion}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          <FormattedMessage id="ok" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default ModalLicense
