import { Action } from '../actions/actions'

const INITIAL_STATE = {
  Reports: [],
  TotalCount: 0,
}

function ReportsReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.GotReportRules:
      return {
        ...state,
        Reports: action.data.Data.map((item: any) => {
          return {
            ...item.ReportRule,
            Id: Math.random(),
            LastReport: item.LastReport,
            ReportFiles: item.ReportFiles,
          }
        }),
        TotalCount: action?.data?.TotalCount,
      }

    case Action.DeleteReportRule:
      return {
        ...state,
        Reports: state.Reports.filter((item: any) => item.ScheduleRuleName !== action.data.ScheduleRuleName),
      }

    default:
      return state
  }
}

// export function addBinanceFuturesSwap(data: any) {
//   return { type: Action.AddBinanceFuturesSwap, data }
// }
//
// export function deleteBinanceFutures() {
//   return { type: Action.DeleteBinanceFutures }
// }

export default ReportsReducer
